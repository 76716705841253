import React from 'react';
import { Autocomplete,Box,Dialog,Button,TextField,DialogActions,DialogContent,DialogTitle,DialogContentText,Checkbox,useMediaQuery,useTheme,Popover,Select,MenuItem,FormControl,FormControlLabel,InputLabel,Slide,Typography,InputAdornment,Tooltip } from '@mui/material';
import { Close,CheckCircle,ArrowDropDown,KeyboardDoubleArrowLeft } from '@mui/icons-material';
import _ from 'lodash';
import JobSchedulerClient from "clients/JobSchedulerClient";
import { useSnackbar } from 'notistack';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import PaperComponent from 'helper-components/PaperComponent.js';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import utils from 'helper-components/utils';
import "stylesheet/common.css";
import closeIcon from "helper-components/icons/closeIcon.svg";
import ellipse from "helper-components/icons/ellipse.svg";
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const ConfigureAlert = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const getDraggableWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
  }
  const { enqueueSnackbar } = useSnackbar();
  const [Dimensions, setDimensions] = React.useState(getDraggableWindowDimensions());
  const [portalUserData, setPortalUserData] = React.useState(props.portalUserData);
  const [alertType, setAlertType] = React.useState(props.data.alertType ? props.data.alertType : '');
  const [waitingTime, setWaitingTime] = React.useState();
  const [waitingTimeTextValue, setWaitingTimeTextValue] = React.useState();
  const [customWaitingTime, setCustomWaitingTime] = React.useState(0);
  const [alertTimeInterval, setAlertTimeInterval] = React.useState();
  const [alertText, setAlertText] = React.useState();
  const [customTimeInterval, setCustomTimeInterval] = React.useState(1);
  const [selectedUsers, setSelectedUsers] = React.useState(props.selectedUsers);
  const [selectedTimeInterval, setSelectedTimeInterval] = React.useState(false);
  const [thresholdValue, setThresholdValue] = React.useState(props.title === "Update Alert" || props.title === "Delete Alert" ? props.data.threshold : 0);
  const [alertThreshold, setAlertThreshold] = React.useState();
  const [showThreshold, setShowThreshold] = React.useState(false);
  const [sendEmailAlert, setSendEmailAlert] = React.useState(false);
  const [sendSmsAlert, setSendSmsAlert] = React.useState(false);
  const [showCpuAlertExistsMessage, setShowCpuAlertExistsMessage] = React.useState(false);
  const [showMemoryAlertExistsMessage, setShowMemoryAlertExistsMessage] = React.useState(false);
  const [showCameraAlertExistsMessage, setShowCameraAlertExistsMessage] = React.useState(false);
  const [showTempAlertExistsMessage, setShowTempAlertExistsMessage] = React.useState(false);
  const [showCo2AlertExistsMessage, setShowCo2AlertExistsMessage] = React.useState(false);
  const [showNo2AlertExistsMessage, setShowNo2AlertExistsMessage] = React.useState(false);
  const [showNh3AlertExistsMessage, setShowNh3AlertExistsMessage] = React.useState(false);
  const [showCoAlertExistsMessage, setShowCoAlertExistsMessage] = React.useState(false);
  const [showCo2CtnAlertExistsMessage, setShowCo2CtnAlertExistsMessage] = React.useState(false);
  const [showRstcAlertExistsMessage, setShowRstcAlertExistsMessage] = React.useState(false);
  const [showTvocAlertExistsMessage, setShowTvocAlertExistsMessage] = React.useState(false);
  const [thresholdType, setThresholdType] = React.useState(props.title === "Update Alert" || props.title === "Delete Alert" ? props.data.thresholdType : "");
  const [performedCreation, setPerformedCreation] = React.useState(false);
  const [performedUpdate, setPerformedUpdate] = React.useState(false);
  const [performedDeletion, setPerformedDeletion] = React.useState(false);
  const [sliding, setSliding] = React.useState(props.show_alert_dialog ? true : false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [alertName, setAlertName] = React.useState(props.title === "Update Alert" || props.title === "Delete Alert" ? props.data.alertName : "");
  const [phone, setPhone] = React.useState();
  const [countryCode, setCountryCode] = React.useState('+1');
  const [mobileNumber, setMobileNumber] = React.useState();
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [countryFlag,setCountryFlag] = React.useState('US');
  const [isMobileNumberExists, setIsMobileNumberExists] = React.useState(false);
  const [minValue, setMinValue] = React.useState();
  const [maxValue, setMaxValue] = React.useState();
  const [units, setUnits] = React.useState("");
  const [usersWithNoPhoneNumbers, setUsersWithNoPhoneNumbers] = React.useState([]);
  const [showSmsErrorText, setShowSmsErrorText] = React.useState(false);

  React.useEffect(() => {
    if(props.title === "Update Alert" || props.title === "Delete Alert") {
      if(props.data.alertType === "HIGH_CPU" || props.data.alertType === "HIGH_MEMORY_USAGE" || props.data.alertType === "HIGH_TEMP"|| props.data.alertType === "HIGH_CO2" || props.data.alertType === "HIGH_NH3" || props.data.alertType === "HIGH_NO2" || props.data.alertType === "HIGH_CO" || props.data.alertType === "HIGH_CO2_CTN" || props.data.alertType === "HIGH_RSTC" || props.data.alertType === "HIGH_TVOC") {
        setShowThreshold(true);
        setThresholdType(props.data.thresholdType);
        setThresholdValue(props.data.threshold);
      }
      if (props.data.alertType === "HIGH_CPU") {
        setMinValue(0);
        setMaxValue(100);
        setUnits("%");
      } else if(props.data.alertType === "HIGH_MEMORY_USAGE"){
        setMinValue(0);
        setMaxValue(100);
        setUnits("MB");
      }else if(props.data.alertType === "HIGH_TEMP") {
        setMinValue(-20);
        setMaxValue(85);
        setUnits("°C");
      }else if(props.data.alertType === "HIGH_CO2") {
        setMinValue(0);
        setMaxValue(10000);
        setUnits("ppm");
      }else if(props.data.alertType === "HIGH_CO") {
        setMinValue(1);
        setMaxValue(1000);
        setUnits("ppm");
      }else if(props.data.alertType === "HIGH_NH3") {
        setMinValue(1);
        setMaxValue(500);
        setUnits("ppm");
      }else if(props.data.alertType === "HIGH_NO2") {
        setMinValue(0);
        setMaxValue(10);
        setUnits("ppm");
      }else if(props.data.alertType === "HIGH_CO2_CTN") {
        setMinValue(450);
        setMaxValue(2000);
        setUnits("ppm");
      }else if(props.data.alertType === "HIGH_RSTC") {
        setMinValue(0);
        setMaxValue(500000);
        setUnits("Ω")
      }else if(props.data.alertType === "HIGH_TVOC"){
        setMinValue(125);
        setMaxValue(600);
        setUnits("ppb");
      }
      window.selectedUsers = props.selectedUsers;
      window.sendSmsAlert = props.data.smsAlert;
      setWaitingTime(props.data.waitingTime);
      setWaitingTimeTextValue(props.data.waitingTimeTextValue);
      setSendEmailAlert(props.data.emailAlert);
      setSendSmsAlert(props.data.smsAlert);
      let shortCode = checkDataExistsOrNot(props.data.countryShortCode) ? props.data.countryShortCode : "US";
      let countryCode = checkDataExistsOrNot(shortCode) ? "+"+getCountryCallingCode(shortCode) : "+1";
      let number = checkDataExistsOrNot(props.data.phoneNumber) ? props.data.phoneNumber : "";
      setMobileNumber(number.replace(countryCode,""));
      setCountryCode(countryCode);
      setCountryFlag(shortCode);
    } else{
        window.sendSmsAlert = false;
        window.selectedUsers = [];
    }
    setTimeout(() => {
      setIsLoading(false);
    },1000);

    return () => {
        delete window.sendSmsAlert;
        delete  window.selectedUsers;
    }
  }, []);

  const conditionalDisplay = () => {
    return (props.generalUser || JSON.parse(sessionStorage.userDetails).user.email === "support@cariboutech.com") && sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? true : false;
  }

  const handleEditUnlockAlert = () => {
    if(props.title !== "Delete Alert"){
      setSliding(false);
      setTimeout(() => {
        props.close_edit_alert_dialog(false);
      },1000);
    }else{
      props.close_edit_alert_dialog(false);
    }
  }

  const createAlertText = (value) => {
    let dayOrDays = value > 1 ? " days" : " day";
    let text = value + dayOrDays;
    let alertValue = 86400000 * value;
    setAlertText(text);
    setAlertTimeInterval(alertValue);
    setCustomTimeInterval(value);
  }

  const createWaitingTimeText = (value) => {
    let text = value + " seconds";
    setWaitingTimeTextValue(text);
    setWaitingTime(value);
    setCustomWaitingTime(value);
  }

  const handleCreateOrUpdateAlert = () => {
    if (props.title === "Create New Alert") {
      if (!performedCreation) {
        setPerformedCreation(true);
        let allReceivers = [];
        _.forEach(selectedUsers,(user) => {
           let recipient = {};
           recipient.email = user.email;
           recipient.phoneNumber = checkDataExistsOrNot(user.phoneNumber) ? user.phoneNumber : null;
           allReceivers.push(recipient);
        });

        let payload = {
          alertType : alertType,
          alertName : alertName,
          waitingTime : waitingTime,
          thresholdType : showThreshold ? thresholdType : null,
          threshold : showThreshold ? thresholdValue : null,
          receivers : allReceivers,
          emailAlert : sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? false : sendEmailAlert,
          smsAlert : sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? false : sendSmsAlert,
          scopeId: sessionStorage.tenantId,
          userId: conditionalDisplay() ? JSON.parse(sessionStorage.userDetails).user.id : null,
          phoneNumber: checkDataExistsOrNot(countryCode) && checkDataExistsOrNot(mobileNumber) ? countryCode+mobileNumber : "",
          countryShortCode: checkDataExistsOrNot(countryFlag) && checkDataExistsOrNot(mobileNumber) ? countryFlag : ""
        }
        let json = createPublishData("_C",alertType,thresholdType,thresholdValue);
        JobSchedulerClient.createAlert(payload).then((response) => {
          if (response.status === 201 || response.status === 204) {
            enqueueSnackbar("Alert successfully created");
            if(!props.generalUser && sessionStorage.tenantId !== "66c8765e927a13321c085d4b" && alertType !== "CAMERA_MODULE_ALERT") {
              MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
            }
          } else {
            enqueueSnackbar("Failed to create alert")
          }
          handleEditUnlockAlert();
        });
      }
    } else {
      if (!performedUpdate) {
        setPerformedUpdate(true);
        let allReceivers = [];
        _.forEach(selectedUsers,(user) => {
           let recipient = {};
           recipient.email = user.email;
           recipient.phoneNumber = checkDataExistsOrNot(user.phoneNumber) ? user.phoneNumber : null;
           allReceivers.push(recipient);
        });
        window.receivers = allReceivers;
        let payload = {
          id : props.data.id,
          alertType : alertType,
          alertName : alertName,
          waitingTime : waitingTime,
          thresholdType : showThreshold ? thresholdType : null,
          threshold : showThreshold ? thresholdValue : null,
          receivers : allReceivers,
          emailAlert : sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? getEmailAlertVal() : sendEmailAlert,
          smsAlert : sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? getSmsAlertVal() : sendSmsAlert,
          scopeId: sessionStorage.tenantId,
          userId: conditionalDisplay() ? JSON.parse(sessionStorage.userDetails).user.id : null,
          phoneNumber: checkDataExistsOrNot(countryCode) && checkDataExistsOrNot(mobileNumber) ? countryCode+mobileNumber : "",
          countryShortCode: checkDataExistsOrNot(countryFlag) && checkDataExistsOrNot(mobileNumber) ? countryFlag : ""
        }
        JobSchedulerClient.updateAlert(payload).then((res) => {
          if(res.status === 200 || res.status === 204 || res.status === 415){
            enqueueSnackbar("Alert successfully updated");
            if ( ((conditionalDisplay() && props.data.receivers.length > 0) || sessionStorage.tenantId !== "66c8765e927a13321c085d4b") && alertType !== "CAMERA_MODULE_ALERT" ) {
              if (props.data.thresholdType !== thresholdType) {
                let json = createPublishData("_D",props.data.alertType,props.data.thresholdType,props.data.threshold);
                MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
                let updatedJson = createPublishData("_C",alertType,thresholdType,thresholdValue);
                MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(updatedJson), {qos: 2, retain: false}, function() {});
              }else if(props.data.threshold !== thresholdValue){
                let json = createPublishData("_U",alertType,thresholdType,thresholdValue);
                MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
              }
            }
          }else {
            enqueueSnackbar("Failed to update alert");
          }
          handleEditUnlockAlert();
        });
      }
    }
  }

  const createPublishData = (type,alrtType,thrshldType,thresholdVal) => {
    if (alrtType !== "CAMERA_MODULE_ALERT") {
      let json = {};
      let sub_node = alrtType === "HIGH_CPU" ? "CP" : alrtType === "HIGH_MEMORY_USAGE" ? "MM" : alrtType === "HIGH_TEMP" ? "TEMP" : alrtType === "HIGH_CO2" ? "CO2" : alrtType === "HIGH_NH3" ? "NH3" : alrtType === "HIGH_NO2" ? "NO2" : alrtType === "HIGH_CO" ? "CO" : alrtType === "HIGH_CO2_CTN" ? "CO2" : alrtType === "HIGH_RSTC" ? "RSTC" : alrtType === "HIGH_TVOC" ? "TVOC" : "camera-removal-alert";
      let threshldType = thrshldType === "greater_than" ? "_H" : "_L";
      let threshold = "_" + thresholdVal;
      if (alertType === "HIGH_CPU" || alertType === "HIGH_MEMORY_USAGE") {
        json = {
          "sys" : sub_node + threshldType + threshold + type
        }
      }else if (alertType === "HIGH_TEMP" || alertType === "HIGH_CO2" ) {
        json = {
          "co2_sensor" : sub_node + threshldType + threshold + type
        }
      } else if (alertType === "HIGH_NH3" || alertType === "HIGH_NO2" || alertType === "HIGH_CO") {
        json = {
          "air_quality_5_sensor" : sub_node + threshldType + threshold + type
        }
      } else if (alertType === "HIGH_CO2_CTN" || alertType === "HIGH_RSTC" || alertType === "HIGH_TVOC") {
        json = {
          "air_quality_2_sensor" : sub_node + threshldType + threshold + type
        }
      }
      return json;
    }
  }

  const handleDeleteAlert = () => {
    if (!performedDeletion) {
      setPerformedDeletion(true);
      let json = createPublishData("_D",alertType,thresholdType,thresholdValue);
      JobSchedulerClient.deleteAlert(props.data.id).then((data) => {
        if(data.status === 204 || data.status === 200){
          enqueueSnackbar("Alert successfully deleted");
          if ( ((conditionalDisplay() && props.data.receivers.length > 0) || sessionStorage.tenantId !== "66c8765e927a13321c085d4b") && alertType !== "CAMERA_MODULE_ALERT") {
            MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
          }
        }else {
          enqueueSnackbar("Error: Please try again");
        }
        handleEditUnlockAlert();
      });
    }
  }

  const checkIsValidToSubmit = () => {
    if(conditionalDisplay()){
        if(checkDataExistsOrNot(alertType) && checkDataExistsOrNot(alertName) && checkDataExistsOrNot(waitingTime) && (showThreshold ? checkDataExistsOrNot(thresholdType) && checkDataExistsOrNot(thresholdValue) ? thresholdValue >= minValue && thresholdValue <= maxValue : false : true)){
          return false;
        }else {
          return true;
        }
    }else {
        if(checkDataExistsOrNot(alertType) && checkDataExistsOrNot(alertName) && checkDataExistsOrNot(waitingTime) && checkDataExistsOrNot(selectedUsers)){
          if((sendEmailAlert || sendSmsAlert) && (showThreshold ? checkDataExistsOrNot(thresholdType) && checkDataExistsOrNot(thresholdValue) ? thresholdValue >= minValue && thresholdValue <= maxValue : false : true)){
            if(sendSmsAlert ? !showSmsErrorText : true){
                return false;
            } else{
                return true;
            }
          } else{
            return true;
          }
        } else{
          return true;
        }
    }
  }

  const checkSpace = (value, flag) => {
    if(value === " " || value.indexOf(" ") === 0){
        if(value.length <= 1)
            return "";
        return value ? value.trim() : "";
    }
    return value;
  }

  const handleOnChange = (value) => {
    setCountryCode(value);
    document.getElementById("mobileField").focus();
  }

  const checkMobileNumberExists = () => {
    if (checkDataExistsOrNot(mobileNumber) || checkDataExistsOrNot(countryCode)) {
      return checkDataExistsOrNot(countryCode) ? checkDataExistsOrNot(mobileNumber) ? !validMobileNumber(mobileNumber) : true : true;
    }else{
      return true;
    }
  }

  const validMobileNumber = (number) => {
     if (checkDataExistsOrNot(number)) {
        if (number.match("^[0-9]+$") && (number.length >= 7 && number.length <= 15)) {
           return true;
        } else {
           return false;
        }
     } else {
       return false;
     }
  }

  const getEmailAlertVal = () => {
      let receivers = window.receivers;
      if(receivers.length === 0){
          return false;
      }
      return _.some(receivers, (receiver) => {
          if(receiver["email"] !== null){
              return true;
          }
      return false;
      })
  }

  const getSmsAlertVal = () => {
      let receivers = window.receivers;
      if(receivers.length === 0){
          return false;
      }
      return _.some(receivers, (receiver) => {
          if(receiver["phoneNumber"] !== null){
              return true;
          }
      return false;
      })
  }

   const checkForReceiversPhoneNumber = () => {
     let userNames = [];
     _.forEach( window.selectedUsers, (user) => {
         if(user.phoneNumber === null || user.phoneNumber === ""){
             userNames.push(user.name);
         }
     })
     if(userNames.length > 0){
         setUsersWithNoPhoneNumbers(userNames)
         if(window.sendSmsAlert){
             setShowSmsErrorText(true);
         }
     }else{
         setShowSmsErrorText(false)
     }
   }

  return (
    <>
    { props.title !== "Delete Alert" ? (
        <div className={sliding ? 'sliding-div' : 'slideOut-div'}>
            <Box style={{
                display:'flex',
                flexDirection:'column',
            }}>
                <Box style={{marginTop:'18px',marginLeft:'10px'}}>
                  { isLoading ? (
                      <Box>
                        <img src={ellipse}/>
                        <KeyboardDoubleArrowLeft sx={{marginLeft: '-35px',marginBottom: '-1px',fontSize: '2.2rem',color:'#1E1E1E',strokeWidth: '0px',cursor:'pointer'}} />
                      </Box>
                  ) :  (
                      <Box>
                        <img src={closeIcon} style={{cursor:'pointer'}} onClick={() => handleEditUnlockAlert()} />
                      </Box>
                  )}
                </Box>
                <Box style={{marginTop: '12px',marginLeft: '11px',fontSize: '16px',fontWeight: 500}}>
                   {props.title}
                </Box>
            </Box>
            <DialogContent
                sx={{
                   padding: '0px',
                   height: '100%',
                   overflow: Dimensions.width > 600 ? 'hidden' : 'auto',
                   overflowX: 'hidden',
                }}
            >
                <Box style={{margin: '20px 20px 10px 0px', cursor: 'pointer'}}>
                  {
                    (((showCpuAlertExistsMessage && alertType === "HIGH_CPU")|| (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE") || (showTempAlertExistsMessage && alertType === "HIGH_TEMP") || (showCo2AlertExistsMessage && alertType === "HIGH_CO2")|| (showNh3AlertExistsMessage && alertType === "HIGH_NH3") || (showNo2AlertExistsMessage && alertType === "HIGH_NO2")|| (showCoAlertExistsMessage && alertType === "HIGH_CO") || (showCo2CtnAlertExistsMessage && alertType === "HIGH_CO2_CTN") || (showRstcAlertExistsMessage && alertType === "HIGH_RSTC") || (showTvocAlertExistsMessage && alertType === "HIGH_TVOC")) && !conditionalDisplay()) ? (
                       null
                    ) : (
                        <Box sx={{padding: '0px 0px 20px 13px'}}>
                           <TextField
                             variant="standard"
                             inputProps={{ maxLength: 40 }}
                             label="Alert Name"
                             sx={{width: '100%'}}
                             value={alertName}
                             onChange={(event) => {
                               let value = event.target.value;
                               value = checkSpace(value);
                               setAlertName(value);
                             }}
                           />
                        </Box>
                   )}
                   <Box style={{ padding: '0px 10px 10px 4px' }}>
                      <div style={{ fontSize:'14px', fontWeight: '400', padding: '5px 10px'}}>Alert</div>
                      <FormControl
                          variant="standard"
                          sx={{width: '100%', margin: '10px', marginTop: '0px'}}
                      >
                         <InputLabel sx={{margin: '0px'}}>
                            Select Alert
                         </InputLabel>
                         <Select
                            value={alertType}
                            onChange={(event) => {
                              let value=event.target.value;
                              if(value === "HIGH_CPU") {
                                let allData = props.allData ? props.allData : [];
                                let cpuAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      cpuAlertAlreadyExists = true;
                                    }
                                })
                                if (!cpuAlertAlreadyExists) {
                                  setShowCpuAlertExistsMessage(false);
                                } else {
                                  setShowCpuAlertExistsMessage(true);
                                }
                                setMinValue(0);
                                setMaxValue(100);
                                setUnits("%");
                              } else if (value === "HIGH_MEMORY_USAGE") {
                                let allData = props.allData ? props.allData : [];
                                let memoryAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      memoryAlertAlreadyExists = true;
                                    }
                                })
                                if (!memoryAlertAlreadyExists) {
                                  setShowMemoryAlertExistsMessage(false);
                                } else {
                                  setShowMemoryAlertExistsMessage(true);
                                }
                                setMinValue(0);
                                setMaxValue(100);
                                setUnits("MB");
                              } else if(value === "HIGH_TEMP") {
                                let allData = props.allData ? props.allData : [];
                                let tempAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      tempAlertAlreadyExists = true;
                                    }
                                })
                                if (!tempAlertAlreadyExists) {
                                  setShowTempAlertExistsMessage(false);
                                } else {
                                  setShowTempAlertExistsMessage(true);
                                }
                                setMinValue(-20);
                                setMaxValue(85);
                                setUnits("°C");
                              } else if(value === "HIGH_CO2") {
                                let allData = props.allData ? props.allData : [];
                                let co2AlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      co2AlertAlreadyExists = true;
                                    }
                                })
                                if (!co2AlertAlreadyExists) {
                                  setShowCo2AlertExistsMessage(false);
                                } else {
                                  setShowCo2AlertExistsMessage(true);
                                }
                                setMinValue(0);
                                setMaxValue(10000);
                                setUnits("ppm");
                              } else if(value === "HIGH_NO2") {
                                let allData = props.allData ? props.allData : [];
                                let no2AlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      no2AlertAlreadyExists = true;
                                    }
                                })
                                if (!no2AlertAlreadyExists) {
                                  setShowNo2AlertExistsMessage(false);
                                } else {
                                  setShowNo2AlertExistsMessage(true);
                                }
                                setMinValue(0);
                                setMaxValue(10);
                                setUnits("ppm");
                              } else if(value === "HIGH_NH3") {
                                let allData = props.allData ? props.allData : [];
                                let nh3AlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      nh3AlertAlreadyExists = true;
                                    }
                                })
                                if (!nh3AlertAlreadyExists) {
                                  setShowNh3AlertExistsMessage(false);
                                } else {
                                  setShowNh3AlertExistsMessage(true);
                                }
                                setMinValue(1);
                                setMaxValue(500);
                                setUnits("ppm");
                              } else if(value === "HIGH_CO") {
                                let allData = props.allData ? props.allData : [];
                                let coAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      coAlertAlreadyExists = true;
                                    }
                                })
                                if (!coAlertAlreadyExists) {
                                  setShowCoAlertExistsMessage(false);
                                } else {
                                  setShowCoAlertExistsMessage(true);
                                }
                                setMinValue(1);
                                setMaxValue(1000);
                                setUnits("ppm");
                              } else if(value === "HIGH_CO2_CTN") {
                                let allData = props.allData ? props.allData : [];
                                let co2CtnAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      co2CtnAlertAlreadyExists = true;
                                    }
                                })
                                if (!co2CtnAlertAlreadyExists) {
                                  setShowCo2CtnAlertExistsMessage(false);
                                } else {
                                  setShowCo2CtnAlertExistsMessage(true);
                                }
                                setMinValue(450);
                                setMaxValue(2000);
                                setUnits("ppm");
                              }else if(value === "HIGH_RSTC") {
                                let allData = props.allData ? props.allData : [];
                                let rstcAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      rstcAlertAlreadyExists = true;
                                    }
                                })
                                if (!rstcAlertAlreadyExists) {
                                  setShowRstcAlertExistsMessage(false);
                                } else {
                                  setShowRstcAlertExistsMessage(true);
                                }
                                setMinValue(0);
                                setMaxValue(500000);
                                setUnits("Ω");
                              } else if(value === "HIGH_TVOC") {
                                let allData = props.allData ? props.allData : [];
                                let tvocAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      tvocAlertAlreadyExists = true;
                                    }
                                })
                                if (!tvocAlertAlreadyExists) {
                                  setShowTvocAlertExistsMessage(false);
                                } else {
                                  setShowTvocAlertExistsMessage(true);
                                }
                                setMinValue(125);
                                setMaxValue(600);
                                setUnits("ppb");
                              }
                              setAlertType(value);
                              if(value === "HIGH_CPU" || value === "HIGH_MEMORY_USAGE" || value === "HIGH_TEMP" || value === "HIGH_CO2" || value === "HIGH_NH3" || value === "HIGH_NO2" || value === "HIGH_CO" || value === "HIGH_CO2_CTN" || value === "HIGH_RSTC" || value === "HIGH_TVOC") {
                                setShowThreshold(true);
                              } else {
                                setShowThreshold(false);
                                setThresholdType("");
                                setThresholdValue("");
                              }
                            }}
                            style={{
                              width:'100%',
                              marginTop:'16px',
                              textAlign:'left',
                              fontSize:'14px',
                              fontWeight:'400'
                            }}
                         >
                           {props.data.alertType === "HIGH_CPU" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_CPU">CPU Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_MEMORY_USAGE" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_MEMORY_USAGE">Memory Usage Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_TEMP" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_TEMP">Temperature Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_CO2" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_CO2">CO2 Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_NO2" || props.title === "Create New Alert" ? (
                            <MenuItem value="HIGH_NO2">NO2 Alert</MenuItem>
                          ) : null}
                          {props.data.alertType === "HIGH_NH3" || props.title === "Create New Alert" ? (
                            <MenuItem value="HIGH_NH3">NH3 Alert</MenuItem>
                          ) : null}
                           {props.data.alertType === "HIGH_CO" || props.title === "Create New Alert" ? (
                              <MenuItem value="HIGH_CO">CO Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_CO2_CTN" || props.title === "Create New Alert" ? (
                              <MenuItem value="HIGH_CO2_CTN" sx={{display: 'none'}}>CO2 Alert(air quality sensor 2)</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_RSTC" || props.title === "Create New Alert" ? (
                              <MenuItem value="HIGH_RSTC">RSTC Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_TVOC" || props.title === "Create New Alert" ? (
                              <MenuItem value="HIGH_TVOC">TVOC Alert</MenuItem>
                           ) : null}

                         </Select>
                         { showCpuAlertExistsMessage && alertType === "HIGH_CPU" && !conditionalDisplay() ? (
                           <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                             <DialogContentText>
                               <br/>
                               <br/>
                                 Cannot create duplicate CPU alert. Please edit the existing one.
                               </DialogContentText>
                           </DialogContent>
                         ) :
                           null
                         }
                         { showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE" && !conditionalDisplay() ? (
                           <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                             <DialogContentText>
                               <br/>
                               <br/>
                                 Cannot create duplicate Memory Usage alert. Please edit the existing one.
                               </DialogContentText>
                           </DialogContent>
                         ) :
                           null
                         }
                         { showTempAlertExistsMessage && alertType === "HIGH_TEMP" && !conditionalDisplay() ? (
                            <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                              <DialogContentText>
                                <br/>
                                <br/>
                                  Cannot create duplicate TEMPERATURE alert. Please edit the existing one.
                                </DialogContentText>
                            </DialogContent>
                         ) :
                           null
                         }
                         { showCo2AlertExistsMessage && alertType === "HIGH_CO2" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate CO2 alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                         ) :
                            null
                         }
                         { showNh3AlertExistsMessage && alertType === "HIGH_NH3" && !conditionalDisplay() ? (
                            <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                              <DialogContentText>
                                <br/>
                                <br/>
                                  Cannot create duplicate NH3 alert. Please edit the existing one.
                                </DialogContentText>
                            </DialogContent>
                         ) :
                           null
                         }
                          { showNo2AlertExistsMessage && alertType === "HIGH_NO2" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate NO2 alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                          ) :
                            null
                          }
                          { showCoAlertExistsMessage && alertType === "HIGH_CO" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate CO alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                          ) :
                            null
                          }
                          { showCo2CtnAlertExistsMessage && alertType === "HIGH_CO2_CTN" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate CO2(air_quality_2_sensor) alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                          ) :
                            null
                          }
                          { showRstcAlertExistsMessage && alertType === "HIGH_RSTC" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate RSTC alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                          ) :
                            null
                          }
                          { showTvocAlertExistsMessage && alertType === "HIGH_TVOC" && !conditionalDisplay() ? (
                             <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                               <DialogContentText>
                                 <br/>
                                 <br/>
                                   Cannot create duplicate TVOC alert. Please edit the existing one.
                                 </DialogContentText>
                             </DialogContent>
                          ) :
                            null
                          }
                      </FormControl>
                   </Box>
                   {
                     ((showCpuAlertExistsMessage && alertType === "HIGH_CPU") || (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE") || (showTempAlertExistsMessage && alertType === "HIGH_TEMP") || (showCo2AlertExistsMessage && alertType === "HIGH_CO2")|| (showNh3AlertExistsMessage && alertType === "HIGH_NH3")|| (showNo2AlertExistsMessage && alertType === "HIGH_NO2")|| (showCoAlertExistsMessage && alertType === "HIGH_CO") || (showCo2CtnAlertExistsMessage && alertType === "HIGH_CO2_CTN") || (showRstcAlertExistsMessage && alertType === "HIGH_RSTC") || (showTvocAlertExistsMessage && alertType === "HIGH_TVOC")) && !conditionalDisplay() ? (
                       null
                     ) : (
                       <Box sx={{display:'flex',padding: '10px 0px 10px 4px',flexDirection: 'column'}}>
                         <Box sx={{display:'flex',padding: '10px 10px 10px 4px',flexDirection: 'column'}}>
                           <div style={{ fontSize:'14px', fontWeight: '400', padding: '5px 10px'}}>Threshold</div>
                           <Box sx={{display:"flex", marginTop:"7px"}}>
                             <FormControlLabel
                               sx={{margin:'0px 2px'}}
                               control={
                                 <Checkbox
                                   disabled={!showThreshold}
                                   checked={showThreshold}
                                 />
                               }
                             />
                             <span style={{marginTop:"9px",marginRight:"12px",fontSize:"14px",fontWeight:"400"}}>Threshold is</span>
                             <Box>
                               <FormControl
                                 variant="standard"
                                 sx={{minWidth:'120px',marginTop:'4px'}}
                               >
                                 <Select disabled={!showThreshold} value={thresholdType} onChange={(event) => setThresholdType(event.target.value)}>
                                   <MenuItem value="greater_than">greater than</MenuItem>
                                   <MenuItem value="less_than">less than</MenuItem>
                                 </Select>
                               </FormControl>
                             </Box>
                             <Box sx={{width: '17%',marginLeft:'10px',marginTop: '4px'}}>
                               <TextField
                                 disabled={!showThreshold}
                                 type="number"
                                 variant="standard"
                                 value={thresholdValue}
                                 InputProps={{
                                   inputProps: {
                                     min: minValue, max: maxValue,
                                   }
                                 }}
                                 onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                 onChange={(event) => {
                                   let value = event.target.value;
                                   value = checkSpace(value);
                                   setThresholdValue(value);
                                 }}
                               />
                             </Box>
                             {showThreshold ?
                               <span style={{marginTop:"9px",fontSize:"14px",fontWeight:"400"}}>{units}</span>
                             : null}
                           </Box>
                           <Box>
                             {units !== "" && showThreshold ?
                               <span style={{padding: '5px 10px',fontSize: '11px'}}>Threshold value should be in between {minValue} and {maxValue}</span>
                             : null}
                           </Box>
                         </Box>
                         <Box sx={{display:'flex',padding: '10px 10px 10px 4px',flexDirection: 'column'}}>
                            <div style={{ fontSize:'14px', fontWeight: '400', padding: '5px 10px' }}>Waiting Time</div>
                            <PopupState variant="popover" popupId="demo-popup">
                              {(popupState) => (
                                <Box sx={{display:'flex', width:'100%'}}>
                                  <Box onClick={(e) => popupState.open(e)} sx={{display:"flex", flexDirection:"row", borderBottom:"1px solid #0000006e", width:"100%", marginLeft:"12px"}}>
                                    <Box sx={{display:"flex", flex:"20%"}}>{waitingTimeTextValue}</Box>
                                    <Box sx={{display:'flex', marginLeft:'28px',justifyContent:'flex-end'}}>
                                      <ArrowDropDown
                                        sx={{float: 'left', cursor:"pointer", color:"#0000008e"}}
                                      />
                                    </Box>
                                  </Box>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorReference={popupState.anchorEl}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    sx={{top:"4px !important"}}
                                  >
                                    <Box sx={{display:'flex',flexDirection:'column'}}>
                                      <Box sx={{width:'400px'}}>
                                        <FormControl variant="standard" sx={{width:"100%"}}>
                                          <MenuItem value={10} onClick={() => {
                                            setWaitingTimeTextValue("10 seconds");
                                            popupState.close();
                                            setWaitingTime(10);
                                          }}>10 seconds</MenuItem>
                                          <MenuItem value={15} onClick={() => {
                                            setWaitingTimeTextValue("15 seconds");
                                            popupState.close();
                                            setWaitingTime(15);
                                          }}>15 seconds</MenuItem>
                                          <MenuItem value={20} onClick={() => {
                                            setWaitingTimeTextValue("20 seconds");
                                            popupState.close();
                                            setWaitingTime(20);
                                          }}>20 seconds</MenuItem>
                                          <MenuItem > <input type="number" value={customWaitingTime} min={0} max={2678400} defaultValue={0} style={{width:"60px"}}
                                             onChange={(event)=> {
                                               setCustomWaitingTime(event.target.value);
                                             }}
                                            />seconds
                                            <CheckCircle sx={{marginLeft:"6px",color:"#1976d2"}}
                                              onClick={() => {
                                                 if(customWaitingTime>=0) {
                                                   createWaitingTimeText(customWaitingTime);
                                                   popupState.close();
                                                 }
                                              }}
                                            />
                                          </MenuItem>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Popover>
                                </Box>
                              )}
                            </PopupState>
                         </Box>
                         {conditionalDisplay() ? null : (
                             <Box style={{padding:'16px'}}>
                               <FormControlLabel
                                   control={
                                     <Checkbox
                                       checked={sendEmailAlert}
                                       onChange={(event) => {
                                         if(event.target.checked){
                                           setSendEmailAlert(true);
                                         }else{
                                           setSendEmailAlert(false);
                                         }
                                       }}
                                     />
                                   }
                                   label={<Typography sx={{fontSize:'14px',fontWeight:'400'}}>Email</Typography>}
                               />
                               <FormControlLabel
                                   control={
                                       <span>
                                         <Checkbox
                                           checked={sendSmsAlert}
                                           onChange={(event) => {
                                             if(event.target.checked){
                                               setSendSmsAlert(true);
                                                window.sendSmsAlert = true;
                                                checkForReceiversPhoneNumber();
                                             }else{
                                               setSendSmsAlert(false);
                                               window.sendSmsAlert = false;
                                               setShowSmsErrorText(false);
                                             }
                                           }}
                                         />
                                       </span>
                                   }
                                   label={<Typography sx={{fontSize:'14px',fontWeight:'400'}}>SMS</Typography>}
                               />
                             </Box>
                         )}
                         {conditionalDisplay() ? null : (
                            <Box>
                                 <Box style={{ padding: '10px 4px'}}>
                                    <div style={{ fontSize:'14px', fontWeight: '400', padding: '5px 10px' }}>Select Users</div>
                                    <Autocomplete
                                       disabled={props.generalUser}
                                       multiple
                                       id="tags-standard"
                                       options={portalUserData}
                                       getOptionLabel={(option) => option.name}
                                       defaultValue={selectedUsers}
                                       style={{ width: 'auto', marginLeft: '10px' }}
                                       onChange={(event,value) => {
                                         setSelectedUsers(value);
                                         window.selectedUsers = value;
                                         checkForReceiversPhoneNumber();
                                       }}
                                       ListboxProps={{style:{ maxHeight: '150px' }}}
                                       renderInput={(params) => (
                                          <TextField {...params} label="Type user name" variant="standard" />
                                       )}
                                    />
                                 </Box>
                                 <Box style={{ padding: '10px 4px', marginLeft:'12px'}}>
                                    {
                                        showSmsErrorText ?
                                            <Box sx={{fontSize:"15px"}}> {usersWithNoPhoneNumbers.join(', ')} {usersWithNoPhoneNumbers.length > 1 ? "don't" : "doesn't"} have mobile {usersWithNoPhoneNumbers.length > 1 ? "numbers" : "number"} </Box>
                                        :
                                            null
                                    }
                                 </Box>
                             </Box>
                         )}
                       </Box>
                     )
                   }
                </Box>
                <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto 20px'}}>
                    <Button
                        onClick={() => handleEditUnlockAlert()}
                        style={{color: '#007AFF'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{color:'#007AFF'}}
                        disabled={checkIsValidToSubmit()}
                        onClick={() => handleCreateOrUpdateAlert()}
                    >
                        {props.button_name}
                    </Button>
                </div>
            </DialogContent>
        </div>
    ) : (
        <Dialog
           fullWidth={true}
           fullScreen={fullScreen}
           open={props.show_alert_dialog}
           onClose={handleEditUnlockAlert}
           PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
           aria-labelledby="draggable-dialog-title"
        >
             <DialogTitle
                 style={{
                    display: 'flex',
                    flexDirection: 'row',
                 }}
             >
                <Box sx={{flex: '95'}}>{props.title}</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => handleEditUnlockAlert()} sx={{cursor:"pointer"}}/>
                </Box>
             </DialogTitle>
             <DialogContent>
               <DialogContentText>
                 Are you sure you want to delete this alert?
               </DialogContentText>
             </DialogContent>
             <DialogActions sx={{display:'flex', justifyContent:"end", position:"relative", height:"40px"}}>
                <>
                  <Button
                     onClick={() => handleEditUnlockAlert()}
                     style={{color: '#007AFF'}}
                  >
                     Cancel
                  </Button>
                  <Button
                    sx={{color:'#007AFF'}}
                    onClick={() => handleDeleteAlert()}
                  >
                    {props.button_name}
                  </Button>
                </>
             </DialogActions>
        </Dialog>
    )}
    </>
  );
};

export default ConfigureAlert;
